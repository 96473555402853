export function authHeader() {
    // return authorization header with jwt token
    const jwt = JSON.parse(localStorage.getItem('UserLoggedIn'));

    if (jwt && jwt.access_token) {
        return { 'Authorization': 'Bearer ' + jwt.access_token };
    } else {
        return {};
    }
}
