<template>
	<div id="menu-sidebar-left-1" class="bg-white menu menu-box-left menu-active" data-menu-width="320" style="display: block; width: 320px;">
		<!--		<div class="d-flex">-->
		<!--			<a href="#" class="flex-fill icon icon-m text-center color-facebook border-bottom border-right"><i class="fab font-12 fa-facebook-f"></i></a>-->
		<!--			<a href="#" class="flex-fill icon icon-m text-center color-twitter border-bottom border-right"><i class="fab font-12 fa-twitter"></i></a>-->
		<!--			<a href="#" class="flex-fill icon icon-m text-center color-instagram border-bottom border-right"><i class="fab font-12 fa-instagram"></i></a>-->
		<!--			<a href="#" class="flex-fill icon icon-m text-center color-whatsapp border-bottom border-right"><i class="fab font-12 fa-whatsapp"></i></a>-->
		<!--			<a href="#" class="flex-fill icon icon-m text-center color-linkedin border-bottom border-right"><i class="fab font-12 fa-linkedin-in"></i></a>-->
		<!--			<a href="#" class="close-menu flex-fill icon icon-m text-center color-red2-dark border-bottom"><i class="fa font-12 fa-times"></i></a>-->
		<!--		</div>-->
		<div class="pl-3 pr-3 pt-3 mt-4 mb-2">
			<div class="d-flex">
				<div class="flex-grow-1">
					<h1 class="font-22 font-700 mb-0">ADMIN</h1>
					<p class="mt-n2  font-10 font-400">Admin prive app</p>
				</div>
				<div style="position: absolute;right:10px;top:5px" @click="$store.commit('ToggleMenu', false)">
					<i class="fa fa-times fa-1x "></i>
				</div>
			</div>
		</div>

		<div class="mr-3 ml-3 mb-5">
			<span class="text-uppercase font-900 font-11 opacity-30 d-block text-left">Documenten</span>
			<div class="list-group list-custom-small list-icon-0">
				<router-link to="/documents">
					<i class="fa font-14 fa-file color-brown1-dark"></i>
					<span>Documenten</span>
					<i class="fa fa-angle-right"></i>
				</router-link>
				<router-link to="/documents/create">
					<i class="fa font-14 fa-file-upload color-brown1-dark"></i>
					<span>Nieuw Document</span>
					<!--					<span class="badge bg-red2-dark">NEW</span>-->
					<i class="fa fa-angle-right"></i>
				</router-link>
			</div>
		</div>

		<div class="mr-3 ml-3 mb-5">
			<span class="text-uppercase font-900 font-11 opacity-30 d-block text-left">Kilometers</span>
			<div class="list-group list-custom-small list-icon-0">
				<router-link to="/milages">
					<i class="fa font-14 fa-car color-aqua2-dark"></i>
					<span>Ritten</span>
					<i class="fa fa-angle-right"></i>
				</router-link>
				<router-link to="/milages/edit/new">
					<i class="fa font-14 fa-plus color-aqua2-dark"></i>
					<span>Nieuwe rit</span>
					<i class="fa fa-angle-right"></i>
                </router-link>
                <router-link to="/milages/stats">
                    <i class="fa font-14 fa-plus color-aqua2-dark"></i>
                    <span>Statistiek ritten</span>
                    <i class="fa fa-angle-right"></i>
                </router-link>
			</div>
		</div>

		<div class="mr-3 ml-3 mb-5">
			<span class="text-uppercase font-900 font-11 opacity-30 d-block text-left">Uren</span>
			<div class="list-group list-custom-small list-icon-0">
				<a href="#">
					<i class="fa font-14 fa-clock color-green1-dark"></i>
					<span>Uren</span>
					<i class="fa fa-angle-right"></i>
				</a>
				<a href="#">
					<i class="fa font-14 fa-plus color-green1-dark"></i>
					<span>Nieuwe urenregistratie</span>
					<i class="fa fa-angle-right"></i>
				</a>
			</div>
		</div>


		<div class="mr-3 ml-3 mt-4">
			<span class="text-uppercase font-900 font-11 opacity-30">Algemeen</span>
			<div class="list-group list-custom-small list-icon-0">
				<a href="#" v-if="$root.userLoggedIn()" @click="$root.logOut()">
					<i class="fa font-14 fa-power-off color-orange-dark"></i>
					<span>Uitloggen</span>
					<i class="fa fa-angle-right"></i>
				</a>
				<router-link :to="'/login'" v-if="!$root.userLoggedIn()">
					<i class="fa font-14 fa-user color-orange-dark"></i>
					<span>Inloggen</span>
					<i class="fa fa-angle-right"></i>
				</router-link>
			</div>
		</div>

		<!--		<div class="mr-3 ml-3 mt-4 pt-2">-->
		<!--			<span class="text-uppercase font-900 font-11 opacity-30">Account Settings</span>-->
		<!--			<div class="list-group list-custom-small list-icon-0">-->
		<!--				<a data-trigger-switch="sidebar-12-switch-3" href="#" class="border-0">-->
		<!--					<i class="fa font-14 fa-bell color-blue2-dark"></i>-->
		<!--					<span>Notifications</span>-->
		<!--					<div class="custom-control scale-switch ios-switch">-->
		<!--						<input type="checkbox" class="ios-input" id="sidebar-12-switch-3" checked="">-->
		<!--						<label class="custom-control-label" for="sidebar-12-switch-3"></label>-->
		<!--					</div>-->
		<!--					<i class="fa fa-angle-right"></i>-->
		<!--				</a>-->
		<!--			</div>-->
		<!--		</div>-->
		<!--		-->
	</div>
</template>

<script>
	export default {
		name: "LeftMenu",

		methods: {

		}
	}
</script>

<style scoped>

</style>
