<template>
	<div>

		<div class="overflow-hidden">
			<div class="row text-center mb-0">
				<router-link to="/documents" class="col-6 pr-0">
					<div class="card card-style mr-2 mb-3">
						<i class="fa fa-file color-magenta2-dark fa-3x mt-5"></i>
						<h1 class="pt-4">Documenten</h1>
						<p class="font-11 opacity-50 mt-n2">Document management</p>
						<p class="mb-0 pb-0 font-10 opacity-40">Klik om te bekijken</p>
					</div>
				</router-link>

				<router-link to="/milages" class="col-6 pl-0">
					<div class="card card-style ml-2 mb-3">
						<i class="fa fa-car color-highlight fa-3x mt-5"></i>
						<h1 class="pt-4">Kilometers</h1>
						<p class="font-11 opacity-50 mt-n2">Zakelijke ritten</p>
						<p class="mb-0 pb-0 font-10 opacity-40">Klik om te bekijken</p>
					</div>
				</router-link>

				<router-link to="/stock" class="col-6 pr-0">
					<div class="card card-style mr-2 mb-3">
						<i class="fa fa-chart-line color-green1-dark fa-3x mt-5"></i>
						<h1 class="pt-4">Aandelen</h1>
						<p class="font-11 opacity-50 mt-n2">Aandelen aankoop en verkoop</p>
						<p class="mb-0 pb-0 font-10 opacity-40">Klik om te bekijken</p>
					</div>
				</router-link>

				<router-link to="/spots" class="col-6 pl-0">
					<div class="card card-style ml-2 mb-3">
						<i class="fa fa-sun color-aqua-dark fa-3x mt-5"></i>
						<h1 class="pt-4">Windspots</h1>
						<p class="font-11 opacity-50 mt-n2">Wind verwachting GFS model</p>
						<p class="mb-0 pb-0 font-10 opacity-40">Klik om te bekijken</p>
					</div>
				</router-link>
<!--				<a class="col-6 pl-0" href="#" data-menu="menu-listed-menus">-->
<!--					<div class="card card-style ml-2">-->
<!--						<i class="fa fa-power-off color-yellow1-dark fa-3x mt-5"></i>-->
<!--						<h1 class="pt-4">Uitloggen</h1>-->
<!--						<p class="font-11 opacity-50 mt-n2">Powerful and Flexible </p>-->
<!--						<p class="mb-0 pb-0 font-10 opacity-40">Klik om te bekijken</p>-->
<!--					</div>-->
<!--				</a>-->
			</div>
		</div>

		<Menu>
			<template slot="menu-items">
				<router-link class="" to="/documents"><i class="fa fa-file"></i><span>Documenten</span></router-link>
				<a class="#" @click="$root.logOut()" v-if="$root.userLoggedIn()"><i class="fa fa-power-off"></i><span>Uitloggen</span></a>
			</template>
		</Menu>


	</div>
</template>

<script>
	import LeftMenu from "@/components/LeftMenu";
	import Menu from "@/components/Menu";

	export default {
		name: "Home",
		components: {
			Menu

		},

		mounted() {
			if (!this.$root.userLoggedIn()){
				this.$router.push('login');
			}

		}
	}
</script>

<style scoped>

</style>
