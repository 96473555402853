import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      user: {},
      showMenu: false,
  },
  mutations: {
      UserLoggedIn(state, user) {
          state.user = user
          localStorage.setItem('UserLoggedIn', JSON.stringify(user));

      },
      UserLoggedOut(state) {
          state.user = {}
          localStorage.removeItem('UserLoggedIn');
      },
      ToggleMenu(state, toggleState) {
          state.showMenu = toggleState

      },
  },
  actions: {
      userLogged({commit}, user) {
          commit('USER_LOGGED', user)
      }
  },
  modules: {
  }
})
