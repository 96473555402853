<template>
    <div class="home">

		<div class="card card-style">
			<div class="content mt-4 mb-0">
				<h1 class="text-center font-900 font-40 text-uppercase mb-5">Inloggen</h1>
<!--				<p class="bottom-0 text-center color-highlight font-11">Let's get you logged in</p>-->

				<div class="input-style has-icon input-style-1 input-required pb-1" >
					<i class="input-icon fa fa-user color-theme" style="left:10px"></i>
					<span>Emailadres</span>
					<em>(verplicht)</em>
					<input type="text" v-model="email" placeholder="Emailadres">
				</div>
				<div class="input-style has-icon input-style-1 input-required pb-1">
					<i class="input-icon fa fa-lock color-theme" style="left:10px"></i>
					<span>Wachtwoord</span>
					<em>(verplicht)</em>
					<input type="password" v-model="password" placeholder="Wachtwoord">
				</div>
				<p class="bottom-0 text-center color-highlight font-11" v-if="errormessage" style="">{{  errormessage }}</p>

				<button type="button" @click="login()" class="btn btn-m mt-2 mb-4 btn-full bg-highlight text-uppercase font-900">
					<span class="spinner-border spinner-grow-sm" v-if="loading"></span>
					Login
				</button>

<!--				<div class="divider"></div>-->

<!--				<div class="divider mt-4 mb-3"></div>-->

<!--				<div class="d-flex">-->
<!--					<div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-left"><a href="#" class="color-theme">Create Account</a></div>-->
<!--					<div class="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-right"><a href="#" class="color-theme">Forgot Credentials</a></div>-->
<!--				</div>-->
			</div>

		</div>
    </div>
</template>

<script>

    export default {
        name: 'login',
        components: {},
        data: function () {
            return {
                loginerror: false,
                loading: false,
                errormessage: '',
                open: true,
                password: '',
                email: 'jules@fourmedia.nl',
            }
        },
        mounted() {
			console.log(this.$root.url);
			if (this.$root.userLoggedIn()){
				console.log('loggined in');
			}
        },

        methods: {
			login: async function () {

				this.loading = true;
				this.errormessage = '';
				try {

					const response = await this.$http.post(this.$root.url + '/api/jwtlogin', {
						email: this.email,
						password: this.password
					});


					this.$store.commit('UserLoggedIn', response.data);


					this.$router.push('/')
				} catch (exception) {
					console.log(exception);
					if (exception.response.status === 401){
						this.errormessage = "Wij kunnen je niet inloggen met deze gegevens";
					}
				}

				this.loading = false;
			},
			loggedIn(){
				return this.$store.state.user && this.$store.state.user.access_token;
			}
        }
    }
</script>
