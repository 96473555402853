<template>
	<div id="footer-bar" class="footer-bar-1">
<!--		<router-link class="active-nav" to="/"><i class="fa fa-home"></i><span>Home</span></router-link>-->
		<router-link to="/"><i class="fa fa-home"></i><span>Home</span></router-link>
		<slot name="menu-items"></slot>
		<router-link to="/about"><i class="fa fa-cog"></i><span>Instellingen</span></router-link>
	</div>
</template>

<script>
	export default {
		name: "Menu"
	}
</script>

<style scoped>

</style>
