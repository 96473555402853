<template>
	<div class="home">

		<!--		<div class="card card-style preload-img" data-src="https://picsum.photos/536/354" data-card-height="130" style="height: 130px; background-image: url('https://picsum.photos/536/354');">-->
		<!--			<div class="card-center ml-3 text-align-left">-->
		<!--				<h1 class="color-white mb-0">Admin</h1>-->
		<!--				<p class="color-white mt-n1 mb-0">Document opslaan voor administratie</p>-->
		<!--			</div>-->
		<!--						<div class="card-center mr-3">-->
		<!--							<a href="#" data-back-button="" class="btn btn-m float-right rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back Home</a>-->
		<!--						</div>-->
		<!--			<div class="card-overlay bg-black opacity-80"></div>-->
		<!--		</div>-->

		<div class="card card-style">
			<div class="content mb-0">

				<h4>Upload nieuw document</h4>
				<p>
					Upload een afbeelding om deze toe te voegen aan de administratie
				</p>

				<div class="row mb-3">
					<div class="col-6">
						<div class="form-check icon-check">
							<input class="form-check-input" type="radio" name="language" v-model="request.language" value="deu" id="de">
							<label class="form-check-label" for="de">Duits</label>
							<i class="icon-check-1 far fa-square color-gray-dark font-16"></i>
							<i class="icon-check-2 far fa-check-square font-16 color-highlight"></i>
							<small class="color-red2-light" v-if="errors.language && errors.language[0]">{{ errors.language[0] }}</small>
						</div>
					</div>

					<div class="col-6">
						<div class="form-check icon-check">
							<input class="form-check-input" type="radio" name="language" v-model="request.language" value="nld" id="nl">
							<label class="form-check-label" for="nl">Nederlands</label>
							<i class="icon-check-1 far fa-square color-gray-dark font-16"></i>
							<i class="icon-check-2 far fa-check-square font-16 color-highlight"></i>
						</div>
					</div>
				</div>

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Naam document</span>
					<em v-if="errors.name && errors.name[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input class="form-control" type="text" v-model="request.name" placeholder="">
					<small class="color-red2-light" v-if="errors.name && errors.name[0]">{{ errors.name[0] }}</small>
				</div>

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Datum</span>
					<em v-if="errors.date && errors.date[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input type="date" class="form-control" v-model="request.date" placeholder="">
					<small class="color-red2-light" v-if="errors.date && errors.date[0]">{{ errors.date[0] }}</small>
				</div>

				<div class="input-style input-style-2 input-required">
					<span v-if="request.category_id === null">Selecteer een categorie</span>
					<span v-else="">{{ getCategoryNameByID() }} </span>
					<em><i class="fa fa-angle-down"></i></em>
					<select class="form-control" v-model="request.category_id">
						<option :value="null">Maak een keuze</option>
						<option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
					</select>
					<small class="color-red2-light" v-if="errors.category_id && errors.category_id[0]">{{ errors.category_id[0] }}</small>
				</div>


				<div class="row">
					<div class="col-12">
						<div class="file-data">
							<input type="file" class="upload-file bg-highlight shadow-s rounded-s " accept="image/*" @change="readFile">
							<p class="upload-file-text color-white">Selecteer Afbeelding</p>
							<small class="color-red2-light" v-if="errors.image && errors.image[0]">{{ errors.image[0] }}</small>
						</div>

						<div v-for="(file, fileindex) in request.images" :key="fileindex" class="text-left mb-5">
							<div class="row d-block mt-3" style="width: 100%;">
								<div class="col-12 mb-3">
									<img v-if="file.imageData" :src="$root.url + '/api/get-file/'+ file.imageData + '?token=' + token" width="150" class="rounded shadow mr-2 mt-5">
								</div>
								<div class="col-12" style=" height:50px;">
									<button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-dark float-left" @click="recognize(fileindex)" :disabled="!file.imageData">Preview</button>
									<button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-danger float-right" @click="removeFile(fileindex)" :disabled="!file.imageData"><i class="fa fa-trash"></i></button>
								</div>
							</div>

							<div class="row d-block"  style=" min-height:25px;">
								<div class="col-12">

									<i v-if="loading" class="fa fa-spin fa-spinner fa-2x"></i>
									<div v-if="!show_all && file.result">
										{{ file.result.slice(0, 100) }}... <a style="color: #1889a9" @click="show_all = !show_all">Toon meer.</a>
									</div>
									<div v-else-if="file.result">
										{{ file.result }} <a style="color: #1889a9" @click="show_all = !show_all">Toon minder.</a>
									</div>

								</div>
							</div>

							<div class="row d-block">
								<div class="col-12">
									<div v-if="file.log && file.log.status === 'initialized api'">Preparing</div>
									<div v-else-if="file.log">{{ getPercentage(file.log) }} %</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				<div class="row">

					<div class="col-12" v-if="request.images.length > 0">
						<button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-info float-right" @click="saveData()">Opslaan</button>
					</div>
				</div>

				<div class="divider"></div>

			</div>
		</div>

		<Menu>
			<template slot="menu-items">
				<router-link class="" to="/documents"><i class="fa fa-file"></i><span>Documenten</span></router-link>
				<router-link class="active-nav" to="/documents/create"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
			</template>
		</Menu>


	</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';

/* eslint-disable */
import {createWorker, createScheduler, PSM, OEM} from 'tesseract.js';
import Menu from "@/components/Menu.vue";


export default Vue.extend({
	name: 'NewDocument',
	components: {Menu},

	data: function () {
		return {
			show_all: false,
			worker: {},
			result: '',
			imageData: null,
			loading: false,
			categories: [],
			log: {},
			loading_percentage: 0,
			errormessage: '',
			data: {},
			errors: {},
			token: null,
			request: {
				date: moment().format('YYYY-MM-DD'),
				language: 'nld',
				category_id: null,
				image: '',
				images: [],
			},
		}
	},


	mounted() {

		this.getCategories();
		this.token = this.$authHeader().Authorization;

	},

	methods: {

		async getCategories() {

			this.$root.loading = true;

			try {

				const response = await this.$http.get(this.$root.url + '/api/get-categories', {
					headers: this.$authHeader(),
				});

				this.categories = response.data;
				console.log(response)


			} catch (error) {
				this.errormessage = error.response;
				console.log(error);
			}

			this.$root.loading = false;

		},


		getCategoryNameByID() {

			if (!this.request.category_id) {
				return 'Maak een keuze';
			}

			const category = this.categories.find(category => {
				return category.id === this.request.category_id
			})

			if (category && category.name) {
				return category.name;
			}
		},

		async readFile(event) {

			console.log('readfile');

			this.data = new FormData();

			var input = event.target;

			console.log(input.files[0]);

			console.log('save image');


			this.saveImage(input.files[0]);

			// if (input.files && input.files[0]) {
			//     var reader = new FileReader();
			//     reader.onload = (e) => {
			//         if (e && e.target) {
			//             const file = input.files[0];
			//
			//
			//             const addedIndex = this.request.images.push(
			//                 {
			//                     org_filename: file.name,
			//                     imageData: e.target.result,
			//                     result: null,
			//                 }) - 1;
			//
			//
			//
			//
			//             // setTimeout(() => {
			//             //     // console.log('Reloading  dropzone..');
			//             //     this.recognize(addedIndex)//  ..  setTimeout()
			//             // }, 200);
			//         }
			//     };
			//
			//     reader.readAsDataURL(input.files[0]);
			// }


		},

		async saveImage(image) {
			this.$root.loading = true;

			console.log(image);

			var params = new FormData();
			params.append('image', image);

			try {

				const response = await this.$http.post(this.$root.url + '/api/save-document', params, {
					headers: this.$authHeader()
				});


				const result = this.request.images.push(
					{
						imageData: response.data,
						result: null,
						log: null,
					}
				) - 1;
				console.log(result)

				this.recognize(result)


			} catch (error) {
				this.errormessage = error.response;
				console.log(error);

				// if (error.response.status === 422) {
				// 	this.errors = error.response.data.errors;
				// }
			}

			this.$root.loading = false;
		},


		async saveData() {

			this.$root.loading = true;

			try {

				const response = await this.$http.post(this.$root.url + '/api/documents', this.request, {
					headers: this.$authHeader()
				});


				this.$router.push('/documents')


			} catch (error) {
				this.errormessage = error.response;
				console.log(error);

				if (error.response.status === 422) {
					this.errors = error.response.data.errors;
				}
			}

			this.$root.loading = false;
		},

		removeFile(file_index) {
			console.log('splice ' + file_index)
			this.request.images.splice(file_index, 1);
		},

		async recognize(fileindex) {

			console.log('recog for index ' + fileindex);


			this.loading = true;

			const img = this.$root.url + '/api/get-file/' + this.request.images[fileindex].imageData + '?token=' + this.token;

			console.log(img)

			const worker = await createWorker({
				logger: m => this.logStuff(m, fileindex),
			});


			(async () => {
				await worker.loadLanguage(this.request.language);
				await worker.initialize(this.request.language);
				await worker.setParameters({
					// tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
				});


				const {data: {text}} = await worker.recognize(img, {rotateAuto: true});

				// this.request.images[fileindex].worker_id = worker.id;
				await worker.terminate();

				this.request.images[fileindex].result = text;
			})();


			this.$forceUpdate();

			// const {data: {text}} = await worker.recognize(img);


			this.loading = false;
		},

		logStuff(m, fileindex) {
			console.log(m);
			this.request.images[fileindex].log = m;
		},


		getPercentage(log) {

			return (log.progress * 100).toFixed(2);

		}
	}


});
</script>

<style>
.bg-highlight, .page-item.active a {
	background-color: #DA4453 !important;
}

.text-align-left {
	text-align: left;
}

.moreisless {
	color: blue;
}

</style>
