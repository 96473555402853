<template>

	<div class="text-left pb-5">


		<div class="card card-style">
			<div class="content mb-0">
				<h4 class="font-700 text-uppercase font-12 opacity-30 mb-3 mt-n2">Aandelen</h4>
				<div class="row mb-3">
					<div class="col-4">
						<p class="font-14 mb-0 font-800 color-theme text-left">Koers x aantal</p>
					</div>
					<div class="col-3"><p class="font-14 mb-0 font-800 color-theme text-center">Prijs p/a</p></div>
					<div class="col-3"><p class="font-14 mb-0 font-800 color-theme text-right">Sale</p></div>
					<div class="divider w-100 mb-2 mt-2"></div>
				</div>
				<div class="row mb-3" v-for="(stock, index) in stocks.data" :key="index">
					<div class="col-4">
						<p class="font-13 mb-0 font-500 color-theme text-left">{{ stock.name }}</p>
						<small>{{ stock.code }} x {{ stock.quantity }}</small>
					</div>
					<div class="col-4">
						<p class="font-13 mb-0 font-800 color-theme"><i class="fa fa-arrow-up color-green1-dark pr-1"></i>
							92,50
						</p>
						<small>Gekocht voor; {{ stock.price }}</small>
					</div>
					<div class="col-4">
						<p class="font-13 mb-0 font-800 color-theme">
							<i class="fa fa-arrow-down color-red2-dark pr-1"></i>
							€ 2.355,60
						</p>
						<small>Gekocht voor; {{ stock.price * stock.quantity }}</small>
					</div>
					<div class="divider w-100 mb-2 mt-2"></div>

				</div>
			</div>
		</div>

		<div class="card card-style">
			<div class="content mb-0">
				<h4 class="font-700 text-uppercase font-12 opacity-30 mb-3 mt-n2">Aandelen</h4>


				<div class="row">
					<div class="col-12">
						<button class="btn btn-sm btn-info float-right"><i class="fa fa-plus"></i></button>
					</div>
				</div>
			</div>
		</div>

		<router-link :to="'/milages/edit/' + stock.id" class="card card-style mb-3 text-left" v-for="(stock, index) in stocks.data" :key="index">
			<div class="content">
				<div class="row mb-0">
					<div class="col-12"><h3>{{ stock.description }}</h3></div>
					<div class="col-6">
						<p class="font-11 mt-n2 mb-0 opacity-50">
							Aantal km: {{ stock.name }}km <br> {{ stock.quantity }} t/m {{ stock.code }}
						</p>
					</div>
					<div class="col-6">
						<span class="bg-blue2-dark badge float-right color-white font-10 mt-2" v-if="stock.customer">{{ stock.customer.name }}</span>
						<span class="bg-orange-dark badge float-right color-white font-10 mt-2" v-else="">Geen klant</span>
						<div class="clearfix"></div>
						<div class="font-10 text-right opacity-30 text-black-50">
							<i class="fa fa-calendar pr-2"></i><span>{{ stock.created_at | moment('DD-MM-YYYY') }}</span>
						</div>
					</div>
				</div>
			</div>
		</router-link>


		<Menu>
			<template slot="menu-items">
				<router-link class="" :to="`/stock-edit/new`"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
				<a class="#" @click="$root.logOut()" v-if="$root.userLoggedIn()"><i class="fa fa-power-off"></i><span>Uitloggen</span></a>
			</template>
		</Menu>
	</div>

</template>

<script>
	import Menu from "@/components/Menu.vue";

	export default {
		name: "Stock",
		components: {Menu},

		data() {
			return {
				stocks: {},
				errormessage: '',
			}
		},

		mounted() {
			this.getStocks();
		},

		methods: {
			async getStocks() {

				this.$root.loading = true;

				try {

					const response = await this.$http.get(this.$root.url + '/api/get-stocks/', {
						headers: this.$authHeader()
					});

					this.stocks = response.data;

					for (let stock of  response.data.data) {
						this.getAlpha(stock);
					}
					console.log(response)


				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
				}

				this.$root.loading = false;
			},

			async getAlpha(stock) {

				try {

					const response = await this.$http.get(`https://finnhub.io/api/v1/search?q=alfen&token=cfglg91r01qlga2ugmjgcfglg91r01qlga2ugmk0`);

					stock.alpha = response.data['Global Quote'];
					console.log(response.data);

				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
				}

				return 'test';
			}
		},


	}
</script>

<style scoped>

</style>
