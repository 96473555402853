<template>
    <div>
        <div class="card card-style text-left">
            <div class="content">
                <h3>Statistiek kilometers</h3>
                <p>Hieronder vind men een korte opsomming van de kilometer registratie per maand (afgelopen twee maanden)</p>

                <div class="user-slider owl-carousel owl-loaded owl-drag">


                    <div class="owl-stage-outer" v-if="stats.currentmonth">
                        <div class="user-left">
                            <div class="d-flex">
                                <div>
                                    <h3 class="mt-1 mb-0">{{ stats.currentmonth.monthname}}</h3>
                                    <p class="font-10 mt-n1 color-red2-dark">{{ stats.currentmonth.totalentries }} ritten totaal</p>
                                </div>
                            </div>

                            <div class="row mb-0" v-for="(customer, customername) in stats.currentmonth.totalgroupby">
                                <div class="col-6"><strong>{{ customername }}: </strong></div>
                                <div class="col-6">{{getTotalMilage(customer)}} km ({{customer.length}} ritten)</div>

                            </div>
                        </div>
                    </div>

                    <div class="divider mt-3"></div>

                    <div class="owl-stage-outer" v-if="stats.lastmonth">
                        <div class="user-left">
                            <div class="d-flex">
                                <div>
                                    <h3 class="mt-1 mb-0">{{ stats.lastmonth.monthname}}</h3>
                                    <p class="font-10 mt-n1 color-red2-dark">{{ stats.lastmonth.totalentries }} ritten totaal</p>
                                </div>
                            </div>

                            <div class="row mb-0" v-for="(customer, customername) in stats.lastmonth.totalgroupby">
                                <div class="col-6"><strong>{{ customername }}: </strong></div>
                                <div class="col-6">{{getTotalMilage(customer)}} km ({{customer.length}} ritten)</div>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="divider mt-3"></div>
            </div>

        </div>

        <Menu>
            <template slot="menu-items">
                <router-link class="active-nav" to="/milages"><i class="fa fa-car"></i><span>Ritten</span></router-link>
                <router-link class="" to="/milages/edit/new"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
            </template>
        </Menu>

    </div>

</template>

<script>
    import Menu from "../components/Menu";

    export default {
        name: "MilageStats",


        data() {
            return {
                error: '',
                loading: true,
                houredit: false,
                stats: [],
                request: {},
                filter: {
                    page: 1
                },
            }
        },
        components: {Menu},
        mounted: function () {

            this.getMilage();
        },
        methods: {
            async getMilage() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.url + '/api/milage/milagestats', {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.stats = response.data.stats;
                } catch (error) {
                    console.error(error);
                }

                this.loading = false;
            },

            getTotalMilage(customer) {

                let total = 0;
                for (let cust of customer) {
                    total = total + cust.totalmilage
                }
                return total;
            }
        }
    }
</script>

<style scoped>

</style>
