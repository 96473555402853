<template>
    <div class="text-left pb-5">

        <div class="">

<!--            <div class="row">-->
<!--                <div class="col-md-12">-->
<!--                    <h2 class="pull-left">Kilometers</h2>-->
<!--                    <router-link :class="'btn btn-info pull-right'" to="/milageedit/new"><i-->
<!--                            class="fa fa-plus"> </i> Nieuw registratie-->
<!--                    </router-link>-->
<!--                    &lt;!&ndash;<button class="btn btn-primary pull-right" ><i class="fa fa-plus"> </i> Nieuw registratie</button>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->

            <div v-if="milages.length === 0" class="alert alert-info">Geen kilometers gevonden</div>

            <router-link :to="'/milages/edit/' + milage.id" class="card card-style mb-3 text-left" v-for="(milage, index) in milages" :key="index">
                <div class="content">
                    <div class="row mb-0">
                        <div class="col-12"><h3>{{ milage.description }}</h3></div>
                        <div class="col-6">
                            <p class="font-11 mt-n2 mb-0 opacity-50">
                                Aantal km: {{ milage.totalmilage }}km <br> {{ milage.start }} t/m {{ milage.end }}
                            </p>
                        </div>
                        <div class="col-6">
                            <span class="bg-blue2-dark badge float-right color-white font-10 mt-2" v-if="milage.customer">{{ milage.customer.name }}</span>
                            <span class="bg-orange-dark badge float-right color-white font-10 mt-2" v-else="">Geen klant</span>
                            <div class="clearfix"></div>
                            <div class="font-10 text-right opacity-30 text-black-50">
                                <i class="fa fa-calendar pr-2"></i><span>{{  milage.date | moment('DD-MM-YYYY') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </router-link>

            <infinite-loading @infinite="getMilages">
                <div slot="no-more">.</div>
            </infinite-loading>

        </div>

        <Menu>
            <template slot="menu-items">
                <router-link class="active-nav" to="/milages"><i class="fa fa-car"></i><span>Ritten</span></router-link>
                <router-link class="" to="/milages/edit/new"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
            </template>
        </Menu>
    </div>
</template>


<script>

    // import Pagination from "../components/Pagination";

    import Menu from "../components/Menu";

    export default {
        name: 'milageindex',
        data() {
            return {
                error: '',
                loading: true,
                houredit: false,
                milages: [],
                request: {},
                filter: {
                    page: 1
                },
            }
        },
        components: {
            Menu
            // Pagination
        },
        mounted: function () {

            // this.getMilages();
        },
        methods: {

            getMilages($state, page) {

                this.$http.get(this.$root.url + '/api/milage', {
                    headers: this.$authHeader(),
                    params: {
                        page: this.filter.page,
                        search: this.filter.search
                    },
                }).then(({data}) => {

                    console.log($state);
                    console.log(data);
                    if (data.milages.data.length) {
                        this.filter.page += 1;
                        this.milages.push(...data.milages.data);
                        if ($state) {
                            $state.loaded();
                        }

                    } else {
                        $state.complete();
                    }
                });
            },


            createNewMilage() {
                this.$router.navigate('/milage_new/');
            }

        }

    };
</script>
