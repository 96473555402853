<template>
	<div>

		<div class="card card-style text-left" v-if="document">

			<img class="preload-img img-fluid bottom-20 object_fit" v-if="document.pages[0]" :src="$root.url + '/api/get-file/'+ document.pages[0].filename + '?token=' + token">
			<div class="content mb-0">
				<div class="float-left">
					<h1 class="mb-2">{{ document.name }}</h1>

					<div class="clearfix"></div>
				</div>

				<div class="clearfix"></div>
				<div class="divider mt-2 mb-3"></div>

				<div class="row">
					<div class="col-4">
						<strong class="color-theme">Datum:</strong>
						<p class="font-12"><i class="far fa-calendar mr-2"></i>{{ document.date | moment('DD-MM-YYYY') }}</p>
					</div>
					<div class="col-4">
						<strong class="color-theme">Taal:</strong>
						<img class="d-block" v-if="document.lang_iso === 'nld'" style="height: 20px" src="img/icons/netherlands.png">
						<img class="d-block" v-if="document.lang_iso === 'deu'" style="height: 20px" src="img/icons/germany.png">
					</div>
				</div>


				<div v-for="(page, pageindex) in document.pages" v-if="document.pages[0]">
					<h5>Omschrijving pagina {{ pageindex  + 1}} </h5>
					<p v-if="!show_all">
						{{ page.description.slice(0, 250) }} <a href="#" @click="show_all = true">Toon meer</a>
					</p>

					<p v-if="show_all" style="white-space: pre-line">
						{{ page.description }} <a href="#" @click="show_all = false">Toon minder</a>
					</p>
                    <hr>
				</div>

				<div class="d-flex mb-4">

					<div class="w-100">
						<h4>Bestand(en)</h4>
						<p class="color-red2-dark mt-n2 font-10 mb-2">{{ document.pages.length }} document</p>
						<a v-for="page in document.pages" :key="page.id" :href="$root.url + '/api/get-file/'+ page.filename + '?token=' + token" target="_blank">
							<img :src="$root.url + '/api/get-file/'+ page.filename + '?token=' + token" width="150" class="preload-img rounded shadow mr-2">
						</a>
					</div>
				</div>
				<a href="#" class="btn btn-xs rounded-sm  bg-highlight float-right text-uppercase mb-5 " @click="document_to_delete = document"><i class="fa fa-trash"></i></a>


				<div v-if="document_to_delete">

					<div id="menu-action-edge-to-edge" class="menu menu-box-modal rounded-0 menu-active" data-menu-width="320" data-menu-height="240" style="z-index:500;height: 240px; width: 320px; display: block;">
						<div class="menu-title mt-n1">
							<h1>Verwijderen document</h1>
							<p class="color-highlight">Weet je zeker dat je dit document wilt verwijderen?</p>
							<a href="#" class="close-menu" @click="document_to_delete = null"><i class="fa fa-times"></i></a>
						</div>
						<div class="content mb-0 mt-2">
							<div class="divider mb-3"></div>
							<p>
								Weet je zeker dat je dit document wilt verwijderen?
							</p>
							<a href="#" class="close-menu btn btn-l rounded-0 btn-full bg-danger text-white text-uppercase font-800" @click="deleteDocument()">Verwijderen</a>
						</div>
					</div>
					<div class="menu-hider menu-active" style="transform: translate(0px, 0px);">
						<div></div>
					</div>
				</div>

			</div>
		</div>

		<Menu>
			<template slot="menu-items">
				<router-link class="active-nav" to="/documents"><i class="fa fa-file"></i><span>Documenten</span></router-link>
				<router-link class="" to="/documents/create"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
			</template>
		</Menu>


	</div>


</template>

<script>
	import Menu from "@/components/Menu";
	export default {
		name: "Document",
		components: {Menu},
		data: function () {
			return {
				filter: {
					page: 1,
				},
				document: {
					description: '',
					pages: [],
				},
				errormessage: null,
				image: null,
				token: null,
				show_all: false,
				document_to_delete: null,
			}
		},

		mounted() {


			this.token = this.$authHeader().Authorization;

			if (this.$route.params.id) {
				this.getDocument();
			}

		},

		methods: {

			async getDocument() {

				this.$root.loading = true;

				try {

					const response = await this.$http.get(this.$root.url + '/api/documents/' + this.$route.params.id, {
						headers: this.$authHeader()
					});

					this.document = response.data;
					console.log(response)


				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
					// this.$root.checkAuth(error.response);
					// console.log('should redirect here')
				}

				this.$root.loading = false;

			},


			async deleteDocument() {

				this.$root.loading = true;

				if (!this.document.pages) {
					return;
				}

				try {

					const response = await this.$http.delete(this.$root.url + '/api/documents/' + this.$route.params.id, {
						headers: this.$authHeader(),
					});

					this.$router.push('/documents')


				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
				}

				this.$root.loading = false;

			}

		}
	}
</script>

<style scoped>

</style>
