<template>

    <div class="">

        <div class="card card-style">
            <div class="content mb-0">

                <div class="mb-3">
                    <div v-if="$route.params.id === 'new'">
                        <h4>Nieuwe rit</h4>
                        <p>
                            Nieuwe rit toevoegen
                        </p>
                    </div>
                    <div v-else>
                        <h4>Rit wijzigen</h4>
                        <p>
                            Rit wijzigen of verwijderen
                        </p>
                    </div>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Datum</span>
                    <em v-if="errors.date && errors.date[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input type="date" class="form-control" v-model="request.date" placeholder="">
					<small class="color-red2-light" v-if="errors['milage.date']">{{ errors['milage.date'][0] }}</small>
                </div>


                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Naar</span>
                    <select class="form-control" v-model="request.customer_id" @change="updateEndMilage()">
                        <option :value="null">Maak een keuze</option>
                        <option v-for="customer in customers" :value="customer.id">{{ customer.name }} ( {{ customer.milage }})</option>
                    </select>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Start stand</span>
                    <em v-if="errors.start && errors.start[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input class="form-control" type="text" v-model="request.start" required placeholder="">
					<small class="color-red2-light" v-if="errors['milage.start']">{{ errors['milage.start'][0] }}</small>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Eind stand</span>
                    <em v-if="errors.end && errors.end[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input class="form-control" type="text" v-model="request.end" placeholder="">
                    <small class="color-red2-light" v-if="errors['milage.end']">{{ errors['milage.end'][0] }}</small>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Omschrijving</span>
                    <em v-if="errors.description && errors.description[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <textarea class="form-control" rows="3" required v-model="request.description"></textarea>
					<small class="color-red2-light" v-if="errors['milage.description']">{{ errors['milage.description'][0] }}</small>
                </div>

                <div class="row">

                    <div class="col-12">
                        <a href="#" class="btn btn-xs rounded-sm  bg-highlight float-left text-uppercase mb-5 " @click="show_delete_modal = true"><i class="fa fa-trash"></i></a>
                        <button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-info float-right" @click="saveMilage()">Opslaan</button>
                    </div>

                    <div v-if="show_delete_modal">

                        <div id="menu-action-edge-to-edge" class="menu menu-box-modal rounded-0 menu-active" data-menu-width="320" data-menu-height="240" style="z-index:500;height: 240px; width: 320px; display: block;">
                            <div class="menu-title mt-n1">
                                <h1>Verwijderen rit</h1>
                                <p class="color-highlight">Weet je zeker dat je deze rit wilt verwijderen?</p>
                                <a href="#" class="close-menu" @click="show_delete_modal = false"><i class="fa fa-times"></i></a>
                            </div>
                            <div class="content mb-0 mt-2">
                                <div class="divider mb-3"></div>
                                <p>
                                    Weet je zeker dat je deze rit wilt verwijderen
                                </p>
                                <a href="#" class="close-menu btn btn-l rounded-0 btn-full bg-danger text-white text-uppercase font-800" @click="deleteMilage()">Verwijderen</a>
                            </div>
                        </div>
                        <div class="menu-hider menu-active" style="transform: translate(0px, 0px);">
                            <div></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <Menu>
            <template slot="menu-items">
                <router-link class="active-nav" to="/milages"><i class="fa fa-car"></i><span>Ritten</span></router-link>
                <router-link class="" to="/milages/edit/new"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
            </template>
        </Menu>

    </div>

</template>


<script>

    import moment from 'moment';
    import Menu from "../components/Menu";
    // import Datepicker from '../components/Datepicker';

    export default {

        name: 'milageedit',

        data() {
            return {
                error: '',
                errors: {},
                loading: true,
                show_delete_modal: false,
                houredit: false,
                milage: {
                    start: null,
                },
                request: {
                    customer_id: null,
                    end: null,
                    start: null,
                },
                customers: [],
                filter: {
                    page: 1
                },
            }
        },

        components: {Menu},

        mounted() {
            this.getLastMilage();
            this.getCustomers();

            if (this.$route.params.id !== 'new') {
                this.getMilage();
            }

            this.request.date = moment().format('YYYY-MM-DD');
        },

		watch: {
        	'$route.params.id'(){
        		this.request = {};
				this.request.date = moment().format('YYYY-MM-DD');
			}
		},

        methods: {
            async getLastMilage() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.url + '/api/milage/getlastmilage', {
                        headers: this.$authHeader(),
                        params: {},
                    });


                    this.request.start = response.data.milage.end;

                } catch (error) {
                    console.error(error);
                }

                this.loading = false;
            },

            async getCustomers() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.url + '/api/customer', {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.customers = response.data;
                } catch (error) {
                    console.error(error);
                }

                this.loading = false;
            },


            async getMilage() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.url + '/api/milage/getmilage/' + this.$route.params.id, {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.request = response.data.milage;
                } catch (error) {
                    console.error(error);
                }

                this.loading = false;
            },

            async deleteMilage() {
                this.loading = true;

                try {
                    const response = await this.$http.post(this.$root.url + '/api/milage/deletemilage/' + this.$route.params.id, {}, {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.$router.push('/milages');

                    this.request = response.data.milage;
                } catch (error) {
                    console.error(error);
                }


                this.loading = false;
            },

            async saveMilage() {
                this.$root.loading = true;

                this.request.user_id = this.$store.state.user.user.id;
                const milage = {milage: this.request};

                try {

                    const response = await this.$http.post(this.$root.url + '/api/milage', milage, {
                        headers: this.$authHeader()
                    });


                    this.$router.push('/milages');


                } catch (error) {
                    this.errormessage = error.response;
                    console.log(error);

                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                }

                this.$root.loading = false;
            },

            updateEndMilage() {

				if (this.$route.params.id !== 'new') {
					return;
				}

                const selected_customer = this.customers.find(customer => {
                    return customer.id === this.request.customer_id;
                });

                if (!selected_customer) {
                    return;
                }
                this.request.end = parseInt(this.request.start) + parseInt(selected_customer.milage);
                this.request.description = selected_customer.name;

            },

            createNewMilage() {
                this.$router.navigate('/milage_new/');
            }
        }
    };
</script>
