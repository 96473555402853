<template>

    <div class="">

        <div class="card card-style">
            <div class="content mb-0">

                <div class="mb-3">
                    <div v-if="$route.params.id === 'new'">
                        <h4>Nieuwe spot</h4>
                        <p>
                            Nieuwe spot toevoegen
                        </p>
                    </div>
                    <div v-else>
                        <h4>Spot wijzigen</h4>
                        <p>
                            Spot wijzigen of verwijderen
                        </p>
                    </div>
                </div>

				<l-map
					   ref="myMap"
					   @click="addMarker"
					   v-if="loaded"
					   :zoom="mapdata.zoom"
					   style="height: 350px;"
					   :center="mapdata.center"
					   :options="mapdata.mapOptions"
					   @update:center="centerUpdate"
					   @update:zoom="zoomUpdate"
					   @ready="doSomethingOnReady()">

						<l-tile-layer
							:url="mapdata.url"
							:attribution="mapdata.attribution"
						/>

						<l-marker :lat-lng="mapdata.withPopup">
							<l-popup v-if="request.name">
								{{ request.name}}
							</l-popup>
						</l-marker>

				</l-map>


                <div class="input-style input-style-2 input-required mt-4">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Spot name</span>
                    <em v-if="errors.date && errors.date[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input type="text" class="form-control" v-model="request.name" placeholder="">
					<small class="color-red2-light" v-if="errors['spot.date']">{{ errors['spot.date'][0] }}</small>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Lattitude</span>
                    <em v-if="errors.lat && errors.lat[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input class="form-control" type="text" v-model="request.lat" required placeholder="">
					<small class="color-red2-light" v-if="errors['spot.start']">{{ errors['spot.start'][0] }}</small>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Longitude</span>
                    <em v-if="errors.end && errors.lon[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <input class="form-control" type="text" v-model="request.lon" placeholder="">
                    <small class="color-red2-light" v-if="errors['spot.lon']">{{ errors['spot.lon'][0] }}</small>
                </div>

                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active input-style-1-inactive">Omschrijving</span>
                    <em v-if="errors.description && errors.description[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
                    <textarea class="form-control" style="height: 75px" required v-model="request.description"></textarea>
					<small class="color-red2-light" v-if="errors['spot.description']">{{ errors['spot.description'][0] }}</small>
                </div>

                <div class="row">

                    <div class="col-12">
                        <a href="#" class="btn btn-xs rounded-sm  bg-highlight float-left text-uppercase mb-5 " @click="show_delete_modal = true"><i class="fa fa-trash"></i></a>
                        <button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-info float-right" @click="saveSpot()">Opslaan</button>
                    </div>

                    <div v-if="show_delete_modal">

                        <div id="menu-action-edge-to-edge" class="menu menu-box-modal rounded-0 menu-active" data-menu-width="320" data-menu-height="240" style="z-index:500;height: 240px; width: 320px; display: block;">
                            <div class="menu-title mt-n1">
                                <h1>Verwijderen spot</h1>
                                <p class="color-highlight">Weet je zeker dat je deze spot wilt verwijderen?</p>
                                <a href="#" class="close-menu" @click="show_delete_modal = false"><i class="fa fa-times"></i></a>
                            </div>
                            <div class="content mb-0 mt-2">
                                <div class="divider mb-3"></div>
                                <p>
                                    Weet je zeker dat je deze spot wilt verwijderen
                                </p>
                                <a href="#" class="close-menu btn btn-l rounded-0 btn-full bg-danger text-white text-uppercase font-800" @click="deleteSpot()">Verwijderen</a>
                            </div>
                        </div>
                        <div class="menu-hider menu-active" style="transform: translate(0px, 0px);">
                            <div></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <Menu>
            <template slot="menu-items">
                <router-link class="active-nav" to="/spots"><i class="fa fa-sun"></i><span>Spots</span></router-link>
                <router-link class="" to="/spots/edit/new"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
            </template>
        </Menu>

    </div>

</template>


<script>



    import Menu from "../components/Menu";
	import { latLng } from "leaflet";
	import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
    // import Datepicker from '../components/Datepicker';

    export default {

        name: 'WindSpotEdit',

        data() {
            return {
				loaded: false,
                error: '',
                errors: {},
				mapdata: {
					zoom: 7,
					center: latLng(51.740314264571, 5.8351386121574),
					url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
					attribution:
						'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
					withPopup: latLng(47.41322, -1.219482),
					withTooltip: latLng(47.41422, -1.250482),
					currentZoom: 11.5,
					currentCenter: latLng(47.41322, -1.219482),
					showParagraph: false,
					mapOptions: {
						zoomSnap: 0.5
					},
					showMap: true
				},
                loading: true,
                show_delete_modal: false,
                houredit: false,
                spot: {
                    start: null,
                },
                request: {
                    end: null,
                    start: null,
                },
                filter: {
                    page: 1
                },
            }
        },

        components: {Menu, LMap, LTileLayer, LMarker, LPopup, LTooltip},

        mounted() {


			if (this.$route.params.id === 'new') {
				this.loaded = true;
				console.log('mtnd cent lat')
            }else {
				this.getSpot();
			}
        },

		watch: {
        	'$route.params.id'(){
        		this.request = {};
			}
		},

        methods: {

			zoomUpdate(zoom) {
				console.log('zoom update', zoom)
				this.mapdata.currentZoom = zoom;
			},
			centerUpdate(center) {
				console.log('center update', center)
				// this.mapdata.center = center;
			},


			addMarker(event) {
				console.log('adding marker')
				console.log(event.latlng)
				this.mapdata.withPopup = event.latlng;
				this.request.lat = event.latlng.lat;
				this.request.lon = event.latlng.lng;
			},

			async doSomethingOnReady() {
				this.map = this.$refs.myMap.mapObject
			},

            async getSpot() {
                this.loading = true;

                try {
                    const response = await this.$http.get(this.$root.url + '/api/get-spot/' + this.$route.params.id, {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.request = response.data;

					this.setCoords();

					this.loaded = true;


                } catch (error) {
                    console.error(error);
                }

                this.loading = false;
            },

			setCoords() {

				console.log(this.request);
				this.mapdata.zoom = 13;
				this.mapdata.center = latLng(this.request.lat, this.request.lon);
				this.mapdata.currentCenter = latLng(this.request.lat, this.request.lon);
				this.mapdata.withPopup = latLng(this.request.lat, this.request.lon);
			},


            async deleteSpot() {
                this.loading = true;

                try {
                    const response = await this.$http.post(this.$root.url + '/api/delete-spot/' + this.$route.params.id, {}, {
                        headers: this.$authHeader(),
                        params: {},
                    });

                    this.$router.push('/spots');

                    this.request = response.data.milage;
                } catch (error) {
                    console.error(error);
                }


                this.loading = false;
            },

            async saveSpot() {
                this.$root.loading = true;

                this.request.user_id = this.$store.state.user.user.id;

                try {

                    const response = await this.$http.post(this.$root.url + `/api/save-spot/${this.$route.params.id}`, this.request, {
                        headers: this.$authHeader()
                    });

                    this.$router.push('/spots');


                } catch (error) {
                    this.errormessage = error.response;
                    console.log(error);

                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                }

                this.$root.loading = false;
            },

        }
    };
</script>
