import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Axios from 'axios'


import VueMoment from 'vue-moment';
import {authHeader} from './authheader.js';
import InfiniteLoading from 'vue-infinite-loading';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import 'leaflet/dist/leaflet.css';

Vue.use(VueMoment);
Vue.use(InfiniteLoading, { /* options */ });
Vue.prototype.$http = Axios;
Vue.prototype.$authHeader = authHeader;

Vue.config.productionTip = false



new Vue({
    router,
    store,
    data: function () {
        return {
            url: process.env.VUE_APP_API_URL || 'https://uren.302.nl',
            loading: false,
        }
    },


    watch:{
        $route (){
            // Close menu on route change
            this.$store.commit('ToggleMenu', false)
        }
    },

    created() {
        Axios.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;

                if (status !== 422) {
                    // this.$root.notifyGeneralError();
                }

                if (status === 401) {
                    router.replace('/login')
                }

                return Promise.reject(error);
            }
        );
    },

    mounted() {
        console.log('app mounted. checking for cookies ' );

        let cookiedata = {
            access_token: null
        };

        const cookieUser = localStorage.getItem('UserLoggedIn');

        if (cookieUser) {
           cookiedata = JSON.parse(cookieUser);
        }

        if (cookiedata && cookiedata.access_token) {
            this.$store.commit('UserLoggedIn', cookiedata)
        }

        if (this.userLoggedIn() && this.$route.name === 'Login'){
            this.$router.push('/');
        }

        this.$router.beforeEach((to,from,next) => {

            if (to.meta.requiresAuth && !this.userLoggedIn()){
                this.$router.push('/login');
            }
            next();
        });


    },

    methods: {

        getDirection(value) {

            const direction = value;

            return `transform: rotate(${direction}deg);`

        },

        getCloudForecast(cloudcover) {
            const cloudscore = cloudcover;

            if (cloudscore > 80) {
                return 'full-cloudy'
            } else if (cloudscore > 40 && cloudscore <= 80) {
                return 'half-cloudy'
            } else if (cloudscore > 20 && cloudscore <= 40) {
                return 'mostly-sunny'
            } else if (cloudscore <= 20) {
                return 'sunny'
            }

        },

        getRainForecast(precipitation) {
            const rainscore = precipitation;

            if (rainscore > 3) {
                return 'icon-rain3'
            } else if (rainscore >= 2 && rainscore < 3) {
                return 'icon-rain2'
            } else if (rainscore < 2) {
                return 'icon-rain1'
            }
        },



        getWindspeedBar(speed) {

            const data = this.getColorData(speed);

            return `width:${data.percentage1};background-color:${data.hex};`;
        },

        getWindspeedGusts(gusts) {

            const data = this.getColorData(gusts);

            return `width:${data.percentage1};background-color:${data.hex_gusts};`;
        },

        getColorData(windspeed){

            let color = '0, 0, 0, 0';
            let hex = '#ffffff';
            let hex_gusts = '#ffffff';
            let percentage1 = '20%'
            let percentage2 = '40%'
            const calcpercentage = parseInt(windspeed) * 2;

            if (windspeed <= 6) {
                color = '0, 100, 255, 0';
                hex = '#0064ff';
                hex_gusts = '#0064ff';
                percentage1 = `${calcpercentage + 10}%`
                percentage2 = `${calcpercentage + 10}%`
            } else if (windspeed >= 6 && windspeed <= 13) {
                color = '0, 100, 255, 0';
                hex = '#00c7ff';
                hex_gusts = '#00e600';
                percentage1 = `${calcpercentage + 10}%`
                percentage2 = `${calcpercentage + 10}%`
            } else if (windspeed > 13 && windspeed <= 19) {
                color = '0, 230, 0, 0';
                hex = '#00e600';
                hex_gusts = '#00e600';
                percentage1 = `${calcpercentage + 10}%`
                percentage2 = `${calcpercentage + 10}%`
            } else if (windspeed > 19 && windspeed < 25) {
                color = '0, 230, 0, 0';
                hex = '#ff9600';
                hex_gusts = '#faff07';
                percentage1 = `${calcpercentage + 10}%`
                percentage2 = `${calcpercentage + 10}%`
            } else if (windspeed > 25) {
                color = '0, 230, 0, 0';
                hex = '#ff0000';
                hex_gusts = '#ff9600';
                percentage1 = `${calcpercentage + 10}%`
                percentage2 = `${calcpercentage + 10}%`
            }

            return {'color': color, 'hex': hex, 'percentage1': percentage1, 'percentage2': percentage2, 'hex_gusts': hex_gusts}

        },

        userLoggedIn() {
            return this.$store.state.user && this.$store.state.user.access_token;
        },

        logOut(){
            this.$store.commit('UserLoggedOut');


        },

        categoryClass(categoryId){

            if (categoryId === 1){
                return 'bg-highlight';
            }else if (categoryId === 2){
                return 'bg-teal-dark';
            }else if (categoryId === 3){
                return 'bg-sky-dark'
            }else if (categoryId === 4){
                return 'bg-magenta2-dark'
            }else if (categoryId === 5){
                return 'color-brown2-dark'
            }else if (categoryId === 6){
                return 'bg-sky-dark'
            }

        }

    },
    render: h => h(App)
}).$mount('#app')


