<template>
	<div id="app">


		<div class="header header-fixed header-logo-center">
			<a href="index.html" class="header-title">Admin <span v-if="$root.userLoggedIn()">(Welkom {{ $store.state.user.user.name }})</span></a>
			<a href="#" data-back-button class="header-icon header-icon-1" @click="toggleMenu()"><i class="fas fa font-14 fa-align-left color-yellow1-dark"></i></a>
			<a href="#" data-toggle-theme class="header-icon header-icon-4"><i class="fas fa-lightbulb"></i></a>
		</div>



		<div class="d-flex justify-content-center fullscreen-spinner" v-if="$root.loading">
			<div class="spinner-border color-teal-dark" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>


		<left-menu v-show="$store.state.showMenu"></left-menu>

		<div id="nav">


		</div>
		<router-view></router-view>


	</div>
</template>


<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}



</style>
<script>


	import LeftMenu from "@/components/LeftMenu";
	export default {
		components: {LeftMenu},
		mounted() {
			if (this.$root.userLoggedIn()) {
				console.log('loggined in');
			}
		},

		methods: {
			toggleMenu(){



				this.$store.commit('ToggleMenu', !this.$store.state.showMenu);

			}
		}

	}
</script>
