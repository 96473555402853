<template>
	<div class="text-left pb-5">

		<div class="content">
			<div class="search-box search-color bg-dark1-dark rounded-xl bottom-0">
				<i class="fa fa-search" @click="searchDocument()"></i>
				<input type="text" class="border-0" placeholder="Zoek hier...  " data-search="" @keyup.enter="searchDocument()" v-model="filter.search">
			</div>
		</div>

		<router-link :to="'/documents/'+ document.id" class="card card-style mb-3" v-for="(document, index) in list" :key="index">
			<div class="content">
				<h3>{{  document.name }}</h3>
				<p class="font-11 mt-n2 mb-0 opacity-50">
					Aantal bestanden: {{ document.pages.length }}
<!--					{{ document.description.slice(0, 80) }}-->
				</p>
				<div class="row mb-n2 color-theme">
					<div class="col-6 font-10 text-left">
						<span :class="$root.categoryClass(document.category.id)" class="bg-green2-dark badge color-white font-10 mt-2">{{ document.category.name }}</span>
					</div>
					<div class="col-6 font-10 text-right opacity-30">
						<i class="fa fa-calendar pr-2"></i><span>{{  document.date | moment('DD-MM-YYYY') }}</span>
					</div>
				</div>
			</div>
		</router-link>

		<infinite-loading @infinite="getDocuments">
			<div slot="no-more">nm</div>
		</infinite-loading>


		<Menu>
			<template slot="menu-items">
				<router-link class="active-nav" to="/documents"><i class="fa fa-file"></i><span>Documenten</span></router-link>
				<router-link class="" to="/documents/create"><i class="fa fa-plus"></i><span>Toevoegen</span></router-link>
			</template>
		</Menu>

	</div>
</template>

<script>
	import Menu from "@/components/Menu";
	export default {
		name: "Documents",
		components: {Menu},
		data: function () {
			return {
				filter: {
					page: 1,
					search: null,
				},
				documents: [],
				list: [],
				errormessage: null,
				page: 0,
			}
		},

		mounted() {

			// this.getDocuments();
		},

		methods: {

			async getDocuments2() {

				this.$root.loading = true;

				try {

					const response = await this.$http.get(this.$root.url + '/api/documents', {
						headers: this.$authHeader(),
						params: {
							page: this.filter.page,
							search: this.filter.search
						},
					});

					this.documents = response.data;
					console.log(response)


				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
					// this.$root.checkAuth(error.response);
					// console.log('should redirect here')
				}

				this.$root.loading = false;

			},

			searchDocument(){
				this.list = [];
				this.filter.page = 1;
				this.getDocuments()

			},

			getDocuments($state, page) {

				this.$http.get(this.$root.url + '/api/documents', {
					headers: this.$authHeader(),
					params: {
						page: this.filter.page,
						search: this.filter.search
					},
				}).then(({ data }) => {

					console.log($state);
					if (data.data.length) {
						this.filter.page += 1;
						this.list.push(...data.data);
						if ($state){
							$state.loaded();
						}

					} else {
						$state.complete();
					}
				});
			},

		}
	}
</script>

<style scoped>

</style>
