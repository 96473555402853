<template>

	<div class="text-left pb-5">


		<div class="card card-style">
			<div class="content mb-0">
				<h4 class="font-700 text-uppercase font-12 opacity-30 mb-3 mt-n2">Aandeel aanmaken</h4>


				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Naam</span>
					<em v-if="errors.name && errors.name[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input class="form-control" type="text" v-model="request.name" required placeholder="">
					<small class="color-red2-light" v-if="errors['name']">{{ errors['name'][0] }}</small>
				</div>

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Code</span>
					<em v-if="errors.name && errors.name[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input class="form-control" type="text" v-model="request.code" required placeholder="">
					<small class="color-red2-light" v-if="errors['name']">{{ errors['name'][0] }}</small>
				</div>

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Market</span>
					<em v-if="errors.name && errors.name[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input class="form-control" type="text" v-model="request.market" required placeholder="">
					<small class="color-red2-light" v-if="errors['name']">{{ errors['name'][0] }}</small>
				</div>


				<div class="row mb-0">
					<div class="col-6">
						<div class="input-style input-style-2 input-required">
							<span class="color-highlight input-style-1-active input-style-1-inactive">Prijs per aandeel</span>
							<em v-if="errors.name && errors.name[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
							<input class="form-control" type="text" v-model="request.price" required placeholder="">
							<small class="color-red2-light" v-if="errors['name']">{{ errors['name'][0] }}</small>
						</div>
					</div>

					<div class="col-6">
						<div class="input-style input-style-2 input-required">
							<span class="color-highlight input-style-1-active input-style-1-inactive">Aantal</span>
							<em v-if="errors.quantity && errors.quantity[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
							<input class="form-control" type="text" v-model="request.quantity" required placeholder="">
							<small class="color-red2-light" v-if="errors['quantity']">{{ errors['quantity'][0] }}</small>
						</div>
					</div>
				</div>

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Munteenheid</span>
					<em v-if="errors.currency && errors.currency[0]"><i class="fa fa-exclamation-triangle color-red2-light"></i></em>
					<input class="form-control" type="text" v-model="request.currency" required placeholder="">
					<small class="color-red2-light" v-if="errors['currency']">{{ errors['currency'][0] }}</small>
				</div>


				<!--				<div class="row mb-3">-->
				<!--					<div class="col-6">-->
				<!--						<div class="form-check icon-check">-->
				<!--							<input class="form-check-input" type="radio" name="type" v-model="request.type" :value="0" id="0">-->
				<!--							<label class="form-check-label" for="0">Koop</label>-->
				<!--							<i class="icon-check-1 far fa-square color-gray-dark font-16"></i>-->
				<!--							<i class="icon-check-2 far fa-check-square font-16 color-highlight"></i>-->
				<!--							<small class="color-red2-light" v-if="errors.type && errors.type[0]">{{ errors.type[0] }}</small>-->
				<!--						</div>-->
				<!--					</div>-->

				<!--					<div class="col-6">-->
				<!--						<div class="form-check icon-check">-->
				<!--							<input class="form-check-input" type="radio" name="type" v-model="request.type" value="1" id="1">-->
				<!--							<label class="form-check-label" for="1">Verkoop</label>-->
				<!--							<i class="icon-check-1 far fa-square color-gray-dark font-16"></i>-->
				<!--							<i class="icon-check-2 far fa-check-square font-16 color-highlight"></i>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</div>-->

				<div class="input-style input-style-2 input-required">
					<span class="color-highlight input-style-1-active input-style-1-inactive">Type</span>
					<select class="form-control" v-model="request.order_type">
						<option :value="null">Maak een keuze</option>
						<option :value="0">Koop</option>
						<option :value="1">Verkoop</option>
					</select>
				</div>

				<div class="row">
					<div class="col-12">
						<button class="btn btn-m btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s btn-info float-right" @click="saveStock()">Opslaan</button>
					</div>
				</div>

			</div>
		</div>


		<Menu>
			<template slot="menu-items">
				<router-link class="" to="/stock"><i class="fa fa-plus"></i><span>Aandelen</span></router-link>
				<a class="#" @click="$root.logOut()" v-if="$root.userLoggedIn()"><i class="fa fa-power-off"></i><span>Uitloggen</span></a>
			</template>
		</Menu>
	</div>

</template>

<script>
	import Menu from "@/components/Menu.vue";

	export default {
		name: "StockEdit",
		components: {Menu},

		data() {
			return {
				stock: {},
				errormessage: '',
				request: {
					order_type: null,
				},
				errors: {}
			}
		},

		mounted() {

			if (this.$route.params.id !== 'new') {
				this.getStock();
			}

		},

		methods: {
			async getStock() {

				this.$root.loading = true;

				try {

					const response = await this.$http.get(`${this.$root.url}/api/get-stock/${this.$route.params.id}`, {
						headers: this.$authHeader()
					});

					this.stocks = response.data;
					console.log(response)


				} catch (error) {
					this.errormessage = error.response;
					console.log(error);
				}

				this.$root.loading = false;
			},

			async saveStock() {
				this.$root.loading = true;


				try {
					const response = await this.$http.post(`${this.$root.url}/api/save-stock/${this.$route.params.id}`, this.request, {
						headers: this.$authHeader(),
						params: {},
					});

					console.log(response)


				} catch (error) {
					console.error(error);
				}


				this.$root.loading = false;
			}
		},
	}
</script>

<style scoped>

</style>
