import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Documents from '../views/Documents.vue'
import NewDocument from '../views/NewDocument.vue'
import Document from '../views/Document.vue'
import Wind from '../views/Wind.vue'
import Spots from '../views/WindSpots.vue'
import MilageIndex from '../views/MilageIndex.vue'
import MilageEdit from '../views/MilageEdit.vue'
import MilageStats from '../views/MilageStats.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Stock from '../views/Stock.vue'
import StockEdit from '../views/StockEdit.vue'
import WindSpots from "@/views/WindSpots.vue";
import WindSpotEdit from "@/views/WindSpotEdit.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documents',
        name: 'Documents',
        component: Documents,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/spots/:id',
        name: 'Wind',
        component: Wind,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/spots',
        name: 'Spots',
        component: WindSpots,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/spots/edit/:id',
        name: 'SpotEdit',
        component: WindSpotEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stock',
        name: 'Stock',
        component: Stock,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/stock-edit/:id',
        name: 'StockEdit',
        component: StockEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/milages',
        name: 'MilageIndex',
        component: MilageIndex,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/milages/edit/:id',
        name: 'MilageEdit',
        component: MilageEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/milages/stats',
        name: 'MilageStats',
        component: MilageStats,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documents/create',
        name: 'NewDocument',
        component: NewDocument,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documents/:id',
        name: 'Document',
        component: Document,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
